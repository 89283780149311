export function gravityFormRealTimeValidation() {
  const forms = document.querySelectorAll(".gform_wrapper");
  if (forms.length < 1) return;

  function validateInput(e) {
    if (e.target.tagName.toLowerCase() === "select") {
      return;
    }
    const parentGfield = e.target.closest(".gfield");
    if (!parentGfield) return;

    if (e.target.type === "email") {
      const emailRegex =
        /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)$/;

      if (e.target.value.match(emailRegex)) {
        parentGfield.classList.add("gfield_custom_success");
      } else {
        parentGfield.classList.remove("gfield_custom_success");
        parentGfield.classList.add("gfield_error");
      }
    } else if (e.target.type === "tel") {
      const phoneRegex = /^\d+$/;

      if (e.target.value.match(phoneRegex)) {
        parentGfield.classList.add("gfield_custom_success");
      } else {
        parentGfield.classList.remove("gfield_custom_success");
        parentGfield.classList.add("gfield_error");
      }
    } else if (e.target.value) {
      parentGfield.classList.add("gfield_custom_success");
    } else {
      parentGfield.classList.remove("gfield_custom_success");
      parentGfield.classList.add("gfield_error");
    }
  }

  function hideValidationIcons(e) {
    const parentGfield = e.target.closest(".gfield");
    if (parentGfield) {
      parentGfield.classList.remove("gfield_custom_success");
      parentGfield.classList.remove("gfield_error");
    }
  }

  document.addEventListener("change", (e) => {
    validateInput(e);
  });

  document.addEventListener("focusin", (e) => {
    hideValidationIcons(e);
  });
}
