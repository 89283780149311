import { disableScroll, enableScroll} from '../utils/scroll';

export const showMenu = (navbarToggler, header) => {
  navbarToggler.classList.add('menu-open');
  header.classList.add('show');
  disableScroll();
}

export const hideMenu = (navbarToggler, header) => {
  navbarToggler.classList.remove('menu-open');
  header.classList.remove('show');
  enableScroll();
}

export const toggleMobileMenu = (navbarToggler, header) => {
  if(!navbarToggler) return;
  navbarToggler.addEventListener('click', () => (!navbarToggler.classList.contains('menu-open')) ? showMenu(navbarToggler, header) : hideMenu(navbarToggler, header));
  document.addEventListener("keyup", (e) => (e.key === 'Escape' && document.body.classList.contains('disable-scroll')) && hideMenu(navbarToggler, header));
}

export const setStickyHeader = (header) => (window.scrollY >= 100 && header.classList.contains('header--light')) ? !header.classList.contains('sticky') && header.classList.add('sticky') : header.classList.contains('sticky') && header.classList.remove('sticky');
