import Plyr from 'plyr';
import stylesheet from 'plyr/dist/plyr.css';
import { disableScroll, enableScroll} from '../utils/scroll';

const hidePopup = (popup) => {
    popup.classList.add('animate');

    setTimeout(() => {
        popup.classList.remove('show', 'animate');
        enableScroll();
    }, 300);
}

const videoSection = (popup) => {
    const video = popup.querySelector('.section-video-js');

    if (!video) return;
    stylesheet;

    const controls = [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute', 'volume',
        'captions',
        'settings',
        'pip',
        'airplay',
        'fullscreen',
    ];

    const player = new Plyr(video, {
        autoplay: false,
        muted: false,
        controls,
        youtube: {
            showinfo: 0,
            disablekb: 1,
            loop: 0,
            modestbranding: 0,
            playlist: video.dataset.plyrEmbedId,
        },
        vimeo: {
            loop: false,
            autoplay: false,
        },
    });

    player.on('ready', () => {
        popup.classList.contains('show') && player.play();
        popup.previousElementSibling.dataset.playerId = player.id;
    });

    document.addEventListener('click', (e) => {
        if (e.target.classList.contains('section-video__btn') && player.id === +e.target.dataset.playerId) {
            player.play();
        } else if (e.target.classList.contains('sk-popup__close') || e.target.classList.contains('sk-popup')) {
            player.pause();
            hidePopup(popup);
        }
    });

    document.addEventListener("keyup", (e) => (e.key === 'Escape' && popup?.classList.contains('show')) && player.pause() && hidePopup(popup));
}

export const showPopup = (e) => {
    if(e.target.closest('button.sk-btn-play')) {
        disableScroll();
        const popup = e.target.closest('.section-video__wrapp').nextElementSibling;
        videoSection(popup);
        popup.classList.add('show');

        document.addEventListener("keyup", (e) => (e.key === 'Escape' && popup?.classList.contains('show')) && hidePopup(popup));
    }
}
