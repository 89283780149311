import AOS from 'aos';
import 'aos/dist/aos.css';
import { hideMenu, toggleMobileMenu, setStickyHeader } from './modules/toggleMobileMenu';
import { scrollToAnchor, setActiveSunMenu, changeHeaderPosition } from './modules/AnchorScroll';
import { gravityFormRealTimeValidation } from '../js/modules/gravity-form-real-time-validation'
import { showPopup } from '../js/modules/video';
import { videoLazyLoading } from '../../resources/js/modules/videoLazyLoading';
import { videoPlyrBg } from '../../resources/js/modules/videoPlyr';

const windowWidth = window.innerWidth;
const header = document.querySelector('.header');
const navbarToggler = document.querySelector('.nav-toggler');
const sectionsWithAnchor = [...document.querySelectorAll('section[id]')];
const lazyVideos = document.querySelectorAll('video.lazy');
const embedVideos = document.querySelectorAll('.section__video-bg');
const videoPlayBtns = document.querySelectorAll('.sk-btn-play');
const subMenu = document.querySelector('.section-sub-navigation');

window.addEventListener('load', () => (navbarToggler && header) && toggleMobileMenu(navbarToggler, header));
window.addEventListener('resize', () => (window.innerWidth != windowWidth && window.innerWidth >= 769 && navbarToggler.classList.contains('menu-open')) && hideMenu(navbarToggler, header));

window.addEventListener('scroll', () => {
    header && setStickyHeader(header);
    sectionsWithAnchor && setActiveSunMenu(sectionsWithAnchor);
    subMenu && changeHeaderPosition();
});

document.addEventListener('click', (e) => videoPlayBtns.length > 0 && showPopup(e));

window.onload = () => {
  header && setStickyHeader(header);
  AOS.init({ once: true });
  lazyVideos.length > 0 && videoLazyLoading(lazyVideos);
  embedVideos.length > 0 && videoPlyrBg(embedVideos);

  gravityFormRealTimeValidation();
  scrollToAnchor();
};


