export const videoChangeSrc = (video) => {
    for (let source in video.children) {
        let videoSource = video.children[source];
        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
            videoSource.src = videoSource.dataset.src;
        }
    }

    setTimeout(() => {
      video.load();
      video.classList.remove("lazy");
    }, 1500);
}

export const videoLazyLoading = (lazyVideos) => {
    if ("IntersectionObserver" in window) {
      let lazyVideoObserver = new IntersectionObserver(function(entries) {
        entries.forEach(function(video) {
          if (video.isIntersecting) {
            videoChangeSrc(video.target);
            lazyVideoObserver.unobserve(video.target);
          }
        });
      }, { threshold: 0.1 });

      lazyVideos.forEach(function(lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
}
