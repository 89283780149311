const mobileWidth = 768;
const subMenu = document.querySelector('.section-sub-navigation');
const header = document.querySelector('.header');
const scrollOffset = 40;
let offset;

const setOffset = () => {
  offset = window.innerWidth <= mobileWidth ? header.clientHeight : 0;
  if(subMenu) offset = offset + subMenu.clientHeight;
  return offset;
}

export const scrollToAnchor = () => {
  subMenu && changeHeaderPosition();
  offset = setOffset();

  document.addEventListener('click', (e) => {
    if (e.target.matches('a[href*="#"]')) {
      e.preventDefault();

      const activeSection = document.querySelector(e.target.getAttribute('href'));
      e.target.closest('.section-sub-navigation__menu') && document.querySelector(".section-sub-navigation__menu li a.active")?.classList.remove('active');
      e.target.classList.add('active');
      activeSection && window.scrollTo({ behavior: 'smooth', top: activeSection.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset - ( scrollOffset / 2 ) })
    }
  });
}

export const setActiveSunMenu = (sectionsWithAnchor) => {
  if(!sectionsWithAnchor) return;
  const activeSection = document.querySelector('.inViewport');

  subMenu && sectionsWithAnchor.forEach(section => {
    if (section.getBoundingClientRect().top <= subMenu.getBoundingClientRect().bottom + scrollOffset && section.getBoundingClientRect().bottom > subMenu.getBoundingClientRect().bottom) {
      if (activeSection && section != activeSection) activeSection?.classList.remove('inViewport');

      section.classList.add('inViewport');
      document.querySelector(".section-sub-navigation__menu li a.active").classList.remove('active');
      document.querySelector(`.section-sub-navigation__menu li a[href="#${section.getAttribute('id')}"]`)?.classList.add('active');
    }
  })
}

export const changeHeaderPosition = () => {
  if(window.innerWidth > mobileWidth && subMenu.getBoundingClientRect().top <= header.clientHeight && subMenu.getBoundingClientRect().top !== 0) {
    header.style.transform = `translateY(${subMenu.getBoundingClientRect().top - header.clientHeight}px)`;
  } else if (subMenu.getBoundingClientRect().top > 0 && subMenu.getBoundingClientRect().top !== 0) {
    header.style.transform = 'translateY(0)';
  } else if  (subMenu.getBoundingClientRect().top === 0 ) {
    header.style.transform = 'translateY(-100%)';
  }
}

window.addEventListener('resize', () => setOffset());