import Plyr from 'plyr';
import stylesheet from 'plyr/dist/plyr.css';

export const videoPlyrBg = (videos) => {
  if (videos.length > 0) {
    stylesheet;

    const controls = [
      'mute', // Toggle mute
    ];

    videos.forEach(function (video) {
      const player = new Plyr(video, {
        autoplay: true,
        volume: 0,
        muted: true,
        loop: { active: true },
        controls,
        youtube: { 
            showinfo: 0,
            autoplay: 1,
            disablekb: 1,
            loop: 1,
            modestbranding: 1,
            playlist: video.dataset.plyrEmbedId,
        },
        vimeo: {
          loop: true,
          autoplay: true,
          playsinline: true,
          modestbranding: 1,
        },
      });

      player.on('ready', () => {
        player.volume = 0;
        player.play();
      });
    });
  }
}
